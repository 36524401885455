import React from "react";
import Footer from "./footer";
import {Helmet} from "react-helmet"

export default function Layout({children}) {
    return (
        <div id="page-container">
            <Helmet
                titleTemplate="%s | Bokvi.com"
                defaultTitle="Bokvi.com">
                <meta charSet="utf-8"/>
                <meta name="description" content="Small business management app"/>
            </Helmet>
            <div id="content-wrap">
                {children}
            </div>
            <Footer/>
        </div>
    )
}
